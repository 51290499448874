<template>
	<div class="resourcerequestFormHeader puiformheader">
		<v-layout wrap>
			<v-flex xs12 md4 lg2>
				<pui-field :label="$t('header.resourcerequest.portname')" :value="getPortname"></pui-field>
			</v-flex>
			<v-flex xs12 md4 lg3>
				<pui-field :label="this.$t('header.resourcerequest.resourcetype')" :value="getResourcetype"></pui-field>
			</v-flex>
			<v-flex xs12 md4 lg1>
				<pui-field :label="this.$t('header.resourcerequest.quantity')" :value="getQuantity"></pui-field>
			</v-flex>
			<v-flex xs12 md4 lg3>
				<pui-field :label="this.$t('header.resourcerequest.startdate')" :value="getStartdate"></pui-field>
			</v-flex>
			<v-flex xs12 md4 lg3>
				<pui-field :label="this.$t('header.resourcerequest.enddate')" :value="getEnddate"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'resourcerequest-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'resourcerequest'
		};
	},
	computed: {
		getPortname() {
			return this.model && this.model.portname ? this.model.portname : '';
		},
		getResourcetype() {
			return this.model && this.model.resourcetypename ? this.model.resourcetypename : '';
		},
		getQuantity() {
			return this.model && this.model.quantity ? this.model.quantity : '';
		},
		getStartdate() {
			return this.model && this.model.startdate ? this.$dateTimeUtils.getLocalFormattedDate(this.model.startdate, 'DD/MM/YYYY HH:mm') : '-';
		},
		getEnddate() {
			return this.model && this.model.enddate ? this.$dateTimeUtils.getLocalFormattedDate(this.model.enddate, 'DD/MM/YYYY HH:mm') : '-';
		}
	}
};
</script>
